import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import BlogDetails from "@/components/blog-details";
import { Helmet as Head } from "react-helmet";

const BlogDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Details Page">
        <Head>
          <title>The Importance of Eco-friendly Pest Control | Nifixeci</title>
          <meta
            name="description"
            content="Sustainability has become a primary concern for all and when it
            comes to pest control solutions, the importance of eco-friendliness
            is crucial."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Blog Details" name="Blog" />
        <BlogDetails />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default BlogDetailsPage;
